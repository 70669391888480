import { reactive, toRefs } from 'vue';
import { DataService } from '@/config/dataService/dataService';

const useWeightConverter = () => {
  const state = reactive({
    weightConvertorResult: null,
    weightConvertedQuantity: null,
  });

  const getWeightConversion = async (productId) => {
    try {
      const query = await DataService.get(`api/Product/${productId}`);
      state.weightConvertorResult = query.data.weightsConversion;
    } catch (error) {
      console.error(error);
    }
  };
  function getValueByKey(obj, key) {
    if (obj && key && typeof obj === 'object') {
      return obj[key];
    } else {
      return undefined; // or handle the case when the object or key is not provided
    }
  }
  const getWeightConvtedQuantity = async (productId, uomCode) => {
    try {
      await getWeightConversion(productId);
      state.weightConvertedQuantity = getValueByKey(state.weightConvertorResult, uomCode)
    } catch (error) {
      console.error(error);
    }
  };

  return {
    ...toRefs(state),
    getWeightConversion,
    getWeightConvtedQuantity
  };
};

export default useWeightConverter;
