<template>
  <div>
    <a-modal :visible="true" title="Edit Product" width="90%" wrap-class-name="full-modal" @ok="handleOk"
      @close="handleCancel" @cancel="handleCancel">
      <a-form ref="productFormRef" @finish="onSubmitProduct" :model="productForm" :rules="ProductRules"
        layout="vertical">
        <a-row :gutter="25">
          <a-col :span="6" v-if="productIsOther">
            <a-form-item label="HS code" name="hsCodeId">
              <a-select :size="'small'" disabled v-model:value="productForm.hsCodeId" placeholder="HS Code" show-search
                style="width: 100%" :options="hsCodeData" :not-found-content="hsCodeFetching ? undefined : null"
                :filter-option="false" @search="searchHSCode">
                <template v-if="hsCodeFetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="!productIsOther">
            <a-form-item label="Category" name="categoryId">
              <a-select disabled v-model:value="productForm.categoryId" placeholder="category">
                <a-select-option v-for="(category, index) in CategoryApi" :key="index" :value="category.id">
                  {{ category.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="!productIsOther">
            <a-form-item label="Size" name="sizeId">
              <a-select v-model:value="productForm.sizeId" show-search disabled placeholder="Select a size"
                style="width: 100%" :options="sizeList" :filter-option="selectFilterOption" @change="SelectWeights">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="!productIsOther">
            <a-form-item label="Weight" name="weightId">
              <a-select disabled v-model:value="productForm.weightId" placeholder="Weight" show-search
                style="width: 100%" :options="SizeWeight" :filter-option="selectFilterOption"></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="!productIsOther">
            <a-form-item label="Grade" name="gradeId">
              <a-select v-model:value="productForm.gradeId" show-search disabled placeholder="Select a grade"
                style="width: 100%" :options="gradeList" :filter-option="selectFilterOption"></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="!productIsOther">
            <a-form-item label="Range" name="rangeId">
              <a-select disabled v-model:value="productForm.rangeId" placeholder="Range">
                <a-select-option v-for="(range, index) in RangeApi" :key="index" :value="range.id">
                  {{ range.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="!productIsOther">
            <a-form-item label="Thread" name="threadId">
              <a-select disabled v-model:value="productForm.threadId" placeholder="Thread">
                <a-select-option v-for="(thread, index) in ThreadApi" :key="index" :value="thread.id">
                  {{ thread.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="IncotermI" name="incotermId">
              <a-select :disabled="readOnly" v-model:value="productForm.incotermId" placeholder="Incoterm" show-search
                style="width: 100%" :options="incotermList" :filter-option="selectFilterOption">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item ref="stockLocation" label="Place of Delivery or Collection" name="stockLocation">
              <a-input :disabled="readOnly" v-model:value="productForm.stockLocation"
                placeholder="Place of Delivery or Collection" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item ref="drift" :label="!productIsOther ? 'Drift': 'Approx.weight in Kg'" name="drift">
              <a-input v-model:value="productForm.drift" disabled
                :placeholder="!productIsOther ? 'Drift' : 'Approx.weight in Kg'" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item ref="stockLocationMaps" label="Yard location (Address)" name="stockLocationMaps">
              <a-input :disabled="readOnly" :style="{ width: '100%' }" v-model:value="productForm.stockLocationMaps"
                placeholder="Yard location (City, Country)" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Country" name="countryId">
              <a-select :disabled="readOnly" v-model:value="productForm.countryId" show-search
                placeholder="Select a country" style="width: 100%" :options="countryList"
                :filter-option="selectFilterOption"></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Product condition" name="condition">
              <a-select disabled v-model:value="productForm.condition" placeholder="Product condition">
                <a-select-option :value="1">Brand New</a-select-option>
                <a-select-option :value="2">Unused, Inspected</a-select-option>
                <a-select-option :value="4">Unused, Needs Refurbishment</a-select-option>
                <a-select-option :value="3">Unused, Not Inspected</a-select-option>
                <a-select-option :value="5">Used</a-select-option>
                <a-select-option :value="7">Scrap</a-select-option>
                <a-select-option :value="6">Not Defined</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="!productIsOther">
            <a-form-item label="PSL" name="pslId">
              <a-select v-model:value="productForm.pslId" disabled placeholder="Please choose a PSL">
                <a-select-option v-for="(pslId, index) in PslApi" :key="index" :value="pslId.id">
                  {{ pslId.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Availability" name="availability">
              <a-select disabled v-model:value="productForm.availability" placeholder="Availability">
                <a-select-option :value="1">Ex-stock</a-select-option>
                <a-select-option :value="2">Ex-mill</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="purchaseId">
            <a-form-item label="Possibility of partial sale" name="isPartialSale">
              <a-select :disabled="readOnly" v-model:value="productForm.isPartialSale" placeholder="is partial sale ?">
                <a-select-option :value="1">Not Defined</a-select-option>
                <a-select-option :value="2">Yes</a-select-option>
                <a-select-option :value="3">No</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Units of Measurement" name="unitOfMeasureId">
              <a-select :disabled="readOnly" v-model:value="productForm.unitOfMeasureId"
                placeholder="Please choose a unit" @change="getNewQuantity">
                <a-select-option v-for="(unit, index) in UnitApi" :key="index" :value="unit.id">
                  {{ unit.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item :disabled="readOnly" label="Quantity" name="quantity">
              <a-input-number :disabled="productForm.isPartialSale != 2 && purchaseId != ''" :style="{ width: '100%' }"
                v-model:value="productForm.quantity" placeholder="Quantity" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Purchase Unit Price" name="priceForSales">
              <a-input-number :disabled="readOnly" :style="{ width: '100%' }" v-model:value="productForm.price"
                placeholder="Purchase Unit Price" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Total Purchase Price" name="TotalPrice">
              <a-input-number disabled :style="{ width: '100%' }" v-model:value="totalPrice"
                placeholder="Total Purchase Price " />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Currency" name="currencyId">
              <a-select :disabled="readOnly" v-model:value="productForm.currencyId" placeholder="currency">
                <a-select-option v-for="(currency, index) in CurrencyApi" :key="index" :value="currency.id">
                  {{ currency.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Country of Origin" name="originCountryId">
              <a-select :disabled="readOnly" v-model:value="productForm.originCountryId" show-search style="width: 100%"
                :options="countryList" :filter-option="selectFilterOption"></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item ref="deliveryTime" label="Delivery Time" name="deliveryTime">
              <a-input-number :style="{ width: '100%' }" v-model:value="productForm.deliveryTime"
                placeholder="Delivery Time" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Number of packages" name="numberPackage">
              <a-input-number :style="{ width: '100%' }" v-model:value="productForm.numberPackage"
                placeholder="Number of packages" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, nextTick } from "vue";
import useHsCodeSearch from "@/composable/useHsCodeSearch";
import useWeightConverter from '@/composable/useWeightConverter';

const EditProductModal = {
  name: "EditProductModal",
  props: {
    SaleId: {
      type: String,
      default: "",
    },
    purchaseId: {
      type: String,
      default: "",
    },
    saleId: {
      type: String,
      default: "",
    },
    quantityOnly: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: "",
    },
    product: {
      type: Object,
      default: {},
    },
  },
  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const ProductApi = computed(() => state.ProductApi.data);
    const ProductAreLoading = computed(() => state.ProductApi.loading);
    const SizeApi = computed(() => state.SizeApi.data);
    const CategoryApi = computed(() => state.CategoryApi.data);
    const PslApi = computed(() => state.PslApi.data);
    const CountryApi = computed(() => state.CountryApi.data);
    const GradeApi = computed(() => state.GradeApi.data);
    const IncotermApi = computed(() => state.IncotermApi.data);
    const UnitApi = computed(() => state.UnitApi.data);
    const ThreadApi = computed(() => state.ThreadApi.data);
    const RangeApi = computed(() => state.RangeApi.data);
    const WeightApi = computed(() => state.WeightApi.data);
    const CurrencyApi = computed(() => state.CurrencyApi.data);
    const readOnly = computed(() => false);
    const OTHER = 2;
    var productForm = reactive({
      layout: "vertical",
      name: "",
      hsCodeId: null,
      quantityInitial: null,
      quantityVirtuelle: 0,
      quantityInStock: 0,
      TotalPrice: null,
      priceForSales: 0,
      incoterm: "",
      deliveryTime: "",
      numberPackage: 0,
      stockLocation: "",
      stockLocationMaps: "",
      isPartialSale: 1,
      availability: 1,
      condition: 1,
      emplacementCity: "",
      emplacementCounty: "",
      note: "",
      categoryId: "",
      countryId: "",
      originCountryId: "",
      sizeId: "",
      gradeId: "",
      incotermId: "",
      weightId: "",
      rangeId: "",
      threadId: "",
      unitOfMeasureId: "",
      currencyId: "",
    });
    const { weightConvertedQuantity, getWeightConvtedQuantity } = useWeightConverter();

    var productIsOther = ref(false);
    const productFormRef = ref([]);
    var SizeWeight = ref([]);

    const ProductRules = {
      name: [
        {
          required: true,
          message: "Please add product label",
          trigger: "change",
        },
      ],
      quantity: [
        {
          type: "number",
          required: true,
          message: "Please insert quantity",
          trigger: "change",
        },
      ],
      TotalPrice: [
        {
          type: "number",
          required: true,
          message: "Please add price",
          trigger: "change",
        },
      ],
      numberPackage: [
        {
          type: "number",
          required: true,
          message: "Please add Number of packages",
          trigger: "change",
        },
      ],
      priceForSales: [
        {
          type: "number",
          message: "Please add price",
          trigger: "change",
        },
      ],
      categoryId: [
        {
          required: true,
          message: "Please chose a category",
          trigger: "change",
        },
      ],
      countryId: [
        {
          required: true,
          message: "Please chose a country",
          trigger: "change",
        },
      ],
      sizeId: [
        {
          required: true,
          message: "Please chose size",
          trigger: "change",
        },
      ],
      gradeId: [
        {
          required: true,
          message: "Please chose grade",
          trigger: "change",
        },
      ],
      incotermId: [
        {
          required: true,
          message: "Please chose incoterm",
          trigger: "change",
        },
      ],
      weightId: [
        {
          required: true,
          message: "Please chose grade",
          trigger: "change",
        },
      ],
      rangeId: [
        {
          required: true,
          message: "Please chose grade",
          trigger: "change",
        },
      ],
      threadId: [
        {
          required: true,
          message: "Please chose grade",
          trigger: "change",
        },
      ],
      unitOfMeasureId: [
        {
          required: true,
          message: "Please chose unite",
          trigger: "change",
        },
      ],
      currencyId: [
        {
          required: true,
          message: "Please chose Currency",
          trigger: "change",
        },
      ],
      // PictureProduct: [
      //   {
      //     type: 'number',
      //     required: true,
      //     message: 'Please add one or more photo',
      //     trigger: 'submit',
      //   },
      // ],
      // MTR: [
      //   {
      //     type: 'array',
      //     required: true,
      //     message: 'Add certificat/MTR',
      //     trigger: 'submit',
      //   },
      // ],
      // InspectionReport: [
      //   {
      //     type: 'array',
      //     required: true,
      //     message: 'Add inspection report',
      //     trigger: 'submit',
      //   },
      // ],
    };

    onMounted(() => {
      if (props.purchaseId) {
        const product = {
          purchaseId: props.purchaseId,
          productId: props.productId,
        };
        dispatch("productPurchaseProductData", product);
      }

      handleEditProduct(props.product);

      dispatch("sizeInfo");
      dispatch("categoryInfo");
      dispatch("countryInfo");
      dispatch("gradeInfo");
      dispatch("unitInfo");
      dispatch("threadInfo");
      dispatch("rangeInfo");
      dispatch("weightInfo");
      dispatch("currencyInfo");
      dispatch("incotermInfo");
      dispatch("pslInfo");
    });

    const handleEditProduct = (product) => {
      hsCodeData.value.push({
        value: product.hsCodeId,
        label: product.hsCodeName,
      });
      let quantity = product.quantity ?? product.quantityInitial;
      productIsOther.value = product.productType == OTHER ? true : false;
      productForm.editionMode = true;
      productForm.id = product.id;
      productForm.quantityInitial = product.quantityInitial;
      productForm.quantityVirtuelle = product.quantityVirtuelle;
      productForm.quantityInStock = product.quantityInStock;
      productForm.priceForSales = product.priceForSales;
      productForm.incoterm = product.incoterm;
      productForm.deliveryTime = product.deliveryTime;
      productForm.numberPackage = product.numberPackage;
      productForm.stockLocation = product.stockLocation;
      productForm.stockLocationMaps = product.stockLocationMaps;
      productForm.isPartialSale = Number(product.isPartialSale);
      productForm.availability = Number(product.availability);
      productForm.condition = Number(product.condition);
      productForm.emplacementCity = product.emplacementCity;
      productForm.emplacementCounty = product.emplacementCounty;
      productForm.note = product.note;
      productForm.categoryId = product.categoryId;
      productForm.pslId = product.pslId;
      productForm.drift = product.drift;
      productForm.categoryId = product.categoryId;
      productForm.countryId = product.countryId;
      productForm.originCountryId = product.originCountryId;
      productForm.sizeId = product.sizeId;
      productForm.gradeId = product.gradeId;
      productForm.incotermId = product.incotermId;
      productForm.productType = product.productType;
      productForm.hsCodeId = product.hsCodeId;
      productForm.weightId = product.weightId;
      productForm.rangeId = product.rangeId;
      productForm.threadId = product.threadId;
      productForm.unitOfMeasureId = product.unitOfMeasureId;
      productForm.currencyId = product.currencyId;
      productForm.price = product.price;
      productForm.quantity = quantity;
      productForm.TotalPrice = productForm.price * quantity;

      SelectWeights(productForm.sizeId);
      productForm.visible = true;
    };

    const sizeList = computed(() => {
      SizeApi.value && SizeApi.value.length;
      if (SizeApi.value && SizeApi.value.length) {
        return SizeApi.value.map((country, key) => {
          return {
            key: key + 1,
            label: `${country.name} - ${country.mm}mm`,
            value: country.id,
          };
        });
      } else {
        return [];
      }
    });
    const gradeList = computed(() => {
      if (GradeApi.value && GradeApi.value.length) {
        return GradeApi.value.map((country, key) => {
          return {
            key: key + 1,
            label: country.name,
            value: country.id,
          };
        });
      } else {
        return [];
      }
    });
    var totalPrice = computed(() => {
      return productForm.quantity * productForm.price;
    });
    const incotermList = computed(() => {
      if (IncotermApi.value && IncotermApi.value.length) {
        return IncotermApi.value.map((country, key) => {
          return {
            key: key + 1,
            label: country.name,
            value: country.id,
          };
        });
      } else {
        return [];
      }
    });
    const countryList = computed(() => {
      CountryApi.value && CountryApi.value.length;
      if (CountryApi.value && CountryApi.value.length) {
        return CountryApi.value.map((country, key) => {
          return {
            key: key + 1,
            label: country.name,
            value: country.id,
          };
        });
      } else {
        return [];
      }
    });

    const onSubmitProduct = () => {
      productFormRef.value
        .validate()
        .then(async () => {
          productForm.availability = !!productForm.availability;
          dispatch("productUpdateData", {
            productForm,
          });

          await nextTick();
          productForm.editionMode = false;
          productForm.id = "";
          resetForm();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const resetForm = () => {
      delete productForm.id;
      productForm.editionMode = false;
      productForm.layout = "vertical";
      productForm.quantityInitial = null;
      productForm.quantityVirtuelle = 0;
      productForm.quantityInStock = 0;
      productForm.TotalPrice = null;
      productForm.priceForSales = 0;
      productForm.incoterm = "";
      productForm.deliveryTime = "";
      productForm.numberPackage = null;
      productForm.stockLocation = "";
      productForm.stockLocationMaps = "";
      productForm.isPartialSale = 1;
      productForm.availability = 1;
      productForm.condition = 1;
      productForm.emplacementCity = "";
      productForm.emplacementCounty = "";
      productForm.note = "";
      productForm.categoryId = "";
      productForm.countryId = "";
      productForm.originCountryId = "";
      productForm.sizeId = "";
      productForm.gradeId = "";
      productForm.incotermId = "";
      productForm.weightId = "";
      productForm.rangeId = "";
      productForm.threadId = "";
      productForm.unitOfMeasureId = "";
      productForm.currencyId = "";
    };

    const SelectWeights = (size) => {
      if (WeightApi.value.length) {
        SizeWeight.value = WeightApi.value
          .filter((wieght) => wieght.sizeId === size)
          .map((weight, key) => {
            return {
              key: key + 1,
              label: weight.name,
              value: weight.id,
            };
          });
      }
    };
    const selectFilterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const { searchHSCode, hsCodeData } = useHsCodeSearch(productForm.HSCode);

    const handleOk = () => {
      productForm.productId = props.productId;
      if (props.purchaseId) {
        productForm.purchaseId = props.purchaseId;
      }
      if (props.saleId) {
        productForm.saleId = props.saleId;
      }

      productForm.categoryName = getNameFromArray(
        productForm.categoryId,
        CategoryApi.value
      );
      productForm.sizeName = getNameFromArray(
        productForm.sizeId,
        SizeApi.value
      );
      productForm.weightName = getNameFromArray(
        productForm.weightId,
        WeightApi.value
      );
      productForm.gradeName = getNameFromArray(
        productForm.gradeId,
        GradeApi.value
      );
      productForm.rangeName = getNameFromArray(
        productForm.rangeId,
        RangeApi.value
      );
      productForm.hsCodeName = getNameFromArray(
        productForm.hsCodeId,
        hsCodeData.value
      );
      productForm.countryName = getNameFromArray(
        productForm.countryId,
        CountryApi.value
      );
      productForm.originCountryName = getNameFromArray(
        productForm.originCountryId,
        CountryApi.value
      );
      productForm.unitOfMeasureName = getNameFromArray(
        productForm.unitOfMeasureId,
        UnitApi.value
      );
      productForm.currencyName = getNameFromArray(
        productForm.currencyId,
        CurrencyApi.value
      );
      productForm.currency = getNameFromArray(
        productForm.currencyId,
        CurrencyApi.value
      );
      productForm.incotermName = getNameFromArray(
        productForm.incotermId,
        IncotermApi.value
      );

      productForm.countryName = getNameFromArray(
        productForm.countryId,
        CountryApi.value
      );
      emit("updateProduct", productForm);
    };
    const handleCancel = () => {
      emit("hideEditProductModal", false);
    };

    /// Helpers
    const getNameFromArray = (id, table) => {
      if (!id) return "";

      if (table.length) {
        const element = table.find((element) => element.id === id);

        if (element) return element.name ?? element.label;
      }

      return "";
    };

    const getNewQuantity = async (value) => {
      const unit = UnitApi.value.find(obj => obj.id === value);
      await getWeightConvtedQuantity(productForm.id, unit.code);
      productForm.quantity = weightConvertedQuantity;

    };
    return {
      getNewQuantity,
      handleOk,
      handleCancel,
      hsCodeData,
      productFormRef,
      resetForm,
      ProductRules,
      productForm,
      onSubmitProduct,
      ProductApi,
      ProductAreLoading,
      sizeList,
      gradeList,
      incotermList,
      CategoryApi,
      CountryApi,
      UnitApi,
      ThreadApi,
      RangeApi,
      WeightApi,
      CurrencyApi,
      SizeWeight,
      SelectWeights,
      selectFilterOption,
      countryList,
      searchHSCode,
      productIsOther,
      totalPrice,
      handleEditProduct,
      PslApi,
      readOnly
    };
  },
};

export default EditProductModal;
</script>

<style lang="scss">
#app {
  .ant-upload {
    width: 100%;
  }

  .ant-input-number-input,
  .ant-calendar-picker-input.ant-input {
    height: 38px;
  }

  .button-container {
    align-self: center;

    .ant-btn {
      height: 38px;
      width: 100%;
    }
  }
}

.deal-info {
  margin-bottom: 20px !important;
}

.inner-table table thead tr th:last-child {
  text-align: left !important;
}
</style>
